@import "../../global.scss";

.portfolio-wrapper {
    text-align: center;
    background-color: #201f2f;
    padding: 100px 50px;
    color: #fff;
    overflow: hidden;
    @include mobile{
      padding: 65px;
    }
    .subtitle{
      margin-top: 10px;
      margin-bottom:5%;
      font-weight: 400;
      color: #989898;
      @include mobile{
        margin-bottom: 100px;
      }
    }
    .slider {
        max-width: 800px;
        margin: auto;
        
      }
      
      .slide-track {
        display: flex;
        transition: transform 0.5s ease-in-out;
      }
      
      .slide {
        flex: 0 0 100%;
        max-width: 100%;
        transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
        opacity: 0.5;
        transform: scale(0.8);
      }
      
      .slide.current {
        opacity: 1;
        transform: scale(1);
        @include mobile{
          z-index: 1;
          transform: scale(1.25);
        }
      }
      
      .slide-content {
        position: relative;
        width: 100%;
        height: 500px;
        border-radius: 30px;
        overflow: hidden;
        transition: transform 0.3s ease;
        h3{
          font-size: 1.6em;
          font-weight: 400;
          margin-bottom: 5px;
          @include mobile{
            font-size: 1.1em;
          }
        }
        p{
          font-size: 14px;
          font-weight: 300;
          color: #cfcfcf;
        }
        @include mobile{
          border-radius: 8px;
          height: 85vw;
        }
        .info-icon {
          position: absolute;
          display: inline-block;
          cursor: pointer;
          right: 2%;
          top: 2%;
        }
        
        .info-icon img {
          width: 20px; /* Adjust the size of the info icon */
          height: 20px;
        }
        
        .description {
          display: none;
          position: absolute;
          left: 0%;
          transform: translateX(-95%);
          background-color: rgba(26, 26, 26, 0.7);
          backdrop-filter: blur(10px);
          
          color: #fff;
          padding: 10px;
          border-radius: 5px;
          width: 350px; /* Adjust the width as needed */
          text-align: center;
          z-index: 10;
          p{
            font-weight: 500;
            font-size: 16px;
            @include mobile{
              font-size: 14px;

            }
          }
          @include mobile{
            width: 200px;

          }
        }
        
        .info-icon:hover .description {
          display: block;
        }
      }
      
      .slide-image {
        width: 100%;
        height: 100%;
        @include mobile{
          object-fit: cover;
        }
      }
      
      .slide-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        color: #fff;
        padding: 10px;
        text-align: left;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgb(0, 0, 0) 100%);
        padding-top: 60px;
        padding-bottom: 5%;
        .card_thumb{
          width: 22px !important;
          padding: 2px;
          border-radius: 50%;
          background-color: white;
          vertical-align: top;
        }
        h3{
         
          .site-link{
            filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(206deg) brightness(106%) contrast(108%);
            @include mobile{
              width: 11px;
            }
          }
        }
      }

      .btn-holder{
        position: relative;
        max-width: 955px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 110px;
        width: 100vw; 
        left: 50%; 
        transform: translateX(-50%);


        .slider-btn {
        background-color: transparent;
        border: none;
        font-size: 24px;
        cursor: pointer;
        z-index: 10;
        padding: 0;
        margin-top: -500px;
      
          .next{
            padding: 0;
          }
          .prev{
            padding: 0;

          }
        
          img{
            filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(206deg) brightness(106%) contrast(108%) drop-shadow(2px 2px 2px #000000)
            
          }
          @include mobile{
            margin-top: -300px;
          }
        }
        @include mobile{
          // margin-top: -450px;
          // margin-bottom: 280px;

        }
      }
      
      
    
      
      .pagination {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        gap: 12px;
        background-color: rgba(255, 255, 255, 0.074);
      border-radius: 15px;
      height: 25px;
      width: fit-content;
      padding: 0px 10px;
      align-items: center;
      margin: auto;
      }
      
      .dot {
        height: 8px;
        width: 8px;
        background-color: #717171;
        border-radius: 50%; 
        border: none;
        display: inline-block;
        transition: background-color 0.6s ease;
        cursor: pointer;
        outline: none;
        padding: 0px;
        transform: all 0.4s ease-in-out;
      }
      
      .dot.active {
        background-color: #ffffff; /* active dot color */
        transform: scale(1.3);
      }
  }
  
  