@import "../../global.scss";
.footer{
    padding: 100px;
    background-color: #201f2f;
    color: black;
    text-align:center;
    color: white;
    @include mobile{
        padding: 100px 10px;
    }
}