@import "../../global.scss";

#skills{
  background-color: #14141e;
  padding: 100px;
  min-height: 300px;
  @include mobile{
    padding: 50px 10px;
  }
}
.skills{
  color: white;
  .wrapper{
    max-width: 1100px;
    margin: auto;
    h2{
      text-align: center;
      font-size: 2em;
      color: #d1d1d1;
    }
    .btn-holder{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 3%;
      margin-bottom: 5%;
      .tablinks{
        width: 20%;
        padding: 8px 10px;
        background-color: transparent;
        border:none;
        outline: none;
        color: rgb(186, 186, 186);
        font-weight: 100;
        font-size: 1.1em;
        cursor: pointer;
        &.active{
        color: rgba(94, 229, 234, 0.689);
        font-weight: bold;
        border-bottom: solid 1px rgba(94, 229, 234, 0.589);

      }
      @include mobile{
        margin: auto;
        width: fit-content;
      }
      }
      @include mobile{
        flex-direction: column;
        margin-bottom: 10%;
      }
    }
    .modalWrapper{
      height: auto;overflow:hidden;
      display: flex;justify-content: center;
      transition: height 1.5s ease-in-out;
    }
    .tabcontent{
      max-width: 800px;
      max-height: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      gap: 25px;
      font-size: 16px;
      opacity: 0;
      transition: opacity 0.5s ease-out, height 1.5s ease-in-out;
      .items{
        padding: 10px 20px;
        border: solid 2px rgba(123, 123, 123, 0.264);
        border-radius: 6px;
        color: rgb(94, 180, 234);
        background-color: transparent;
        transition: all 0.25s ease-in-out;

        img{
          vertical-align: bottom;
          width: auto;
          height: 20px;
        }
        &:hover{
          background-color: rgba(123, 123, 123, 0.264);
        }
      }
    }
  }
  
  
 

}

