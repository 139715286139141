@import "../../global.scss";


.menu{
    width: 300px;
    height: 100vh;
    background-color: #201f2fb5;
    backdrop-filter: blur(10px);
    color: white;
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 2s ease;
    p{
        font-size: 1.2rem;
        width: 70%;
        font-weight: lighter;
    }
    .social-menu{
        width: 70%;
        text-align: center;
        display: flex;
        gap: 2rem;
        margin-top: 15px;
    }

    &.active{
        right: 0;
    }
    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 2.2rem;
        font-weight: 300;
        color: white;
        width:70%;

        li{
            margin-bottom: 20px;
            a{
                text-decoration: none;
                font-size: inherit;
                color: inherit;
            }
            &:hover{
                font-weight: 500;
            }
            
        }
        
    }
}
