.app{
    background-color: black;
    // height: 100vh;

    // .sections{
    //     width: 100%;
    //     height: calc(100vh - 70px);
    //     // background-color: lightsalmon;
    //     position: relative;
    //     top: 70px;
    //     scroll-behavior: smooth;
    //     // scroll-snap-type: y mandatory;
    //     scrollbar-width: none;
    //     &::-webkit-scrollbar{
    //         display: none;
    //     }

    //     > *{
    //         width: 100vw;
    //         height: calc(100vh - 70px);
    //         scroll-snap-align: start;
    //     }

    // }
}
.orange-cta{
    padding: 10px 20px;
    background: linear-gradient(90deg, rgba(139,98,255,1) 0%, rgba(4,4,102,1) 92%);
    // background-color: #f8a350;
    color: white;
    font-size: 20px;
    text-decoration: none;
    border-radius: 8px;
}