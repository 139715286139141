@import "../../global.scss";

.topbar{
    width: 100%;
    height: 60px;
    background-color: #201f2f;
    position: fixed;
    top: 0;
    z-index:3;
    transition: all 1s ease;

    .wrapper{
        max-width: 1150px;
        margin: auto;
        padding: 0px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-content: center;
        height: 100%;
    
        .left{
            display: flex;
            align-items: center;
            .logo{
                font-size: 20px;
                font-weight: 700;
                text-decoration: none;
                color: #ffffff;
                margin-right: 30px;
            }
            .itemcontainer{
                display: flex;
                align-items: center;
                margin-left: 30px;

                @include mobile{
                    display: none;
                }
    
                .icon{
                    font-size: 1.5rem;
                    margin-right: 5px;
                }
                span{
                    font-size: 1.2rem;
                    font-weight: 500;
                }
            }
        }
        .right{
            .hamburger{
                background-color: #201f2f;
                color: white;
                width:32px;
                height: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;
                span{
                    width: 100%;
                    height: 3px;
                    background-color: rgb(255, 255, 255);
                    transform-origin: left;
                    transition: all 2s ease;
    
                }
            }
        }
    }
    &.active{
        // background-color: #201f2f;
        // color: white;
        // border-bottom: 10px solid white;
        .hamburger{
            span{
                &:first-child{
                    // background-color: white;
                    transform: rotate(45deg);
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:last-child{
                    // background-color: white;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}
