
@import "../../global.scss";


.contact{
  padding: 5%;  
  @include mobile{
    padding: 0px;
    padding-bottom: 100px;
  }
  h2{
    font-size: 50px;
    color: white;
    margin-bottom: 2%;
  }
  h4{
    font-size: 20px;
    color: #808080;
    margin-bottom: 20px;
  }

  h2, h4 {
    text-align: center;
  }
  .contact-p{
    max-width: 450px;
    margin: auto;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
  }

  .btn-holder{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 25px;
    padding-top: 25px;
    margin-bottom: 50px;
    a{
      color: white;
      background-color: #438fff;
      padding: 10px 25px;
      border-radius: 6px;
      text-decoration: none;
      font-weight: 300;
      font-size: 16px;
    }
  }

  form{
    margin-bottom: 20px;
  
    .form-inner-wrapper{
      text-align: center;
      display: flex;
      justify-content: center;
      gap: 20px;
      padding-bottom: 45px;
      flex-direction: column;
      margin: auto;
      max-width: 600px;
        .input-wrapper{
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 25px;

        input{
          width: 600px;
          height: 40px;
          border-radius: 7px;
          outline: none;
          border: none;
          font-size: 18px;
          padding: 10px;
          box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
          border: 2px solid white;
          background-color: white;
          @include mobile{
            width:90%;
            margin: auto;
          }
          
        }
        input:focus {
            border: 2px solid #9b74b8;

        }
      }
      textarea{
        width: 600px;
        border-radius: 7px;
        font-size: 18px;
        padding: 10px;
        outline: none;
        @include mobile{
          width:90%;
          margin: auto;

        }
      }
      textarea:focus{
        border: 2px solid #9b74b8;
      }
    }
    button{
      padding: 15px 35px;
      width: 300px;
      background-color: #fc8c1d;
      border: none;
      outline: none;
      color: white;
      border-radius: 7px;
      font-size: 24px;
    }
   
  }
  span{
    color: white;
    margin-top:20px;
  }


}
