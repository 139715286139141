@import "../../global.scss";

.intro{
    background-color: #201f2f;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    padding: 100px 5%;
    margin-top: 60px;
    min-height: 50vh;
    h2{
      font-size: 2.5em;
    }
    h1{
      font-size: 2.8em;
      color:rgb(94, 234, 212);
      margin: 2% 0;
      @include mobile{
        span{
          font-size: 0.8em;
        }
      }
    }
    @include mobile{
        flex-direction: column;
    }

    .left{
        flex: 0.5;
        overflow: hidden;
        color: #fff;
        max-width: 450px;
        h3{
          font-size: 0.8em;
          color: #5e5e5e;
          font-weight: 400;
        }
       p{
        color: #bcbcbc;
       }
       .social-menu{
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding: 2%;
       }
    }

    .right{
      flex: 0.3;
      display: flex;
      justify-content: center;
      align-content: center;
      .av-wrapper{
        width: 250px;
        height: 250px;
        background-color: rgba(245, 245, 245, 0.135);
        border-radius: 50%;
        padding: 2%;
        overflow: hidden;
        img{
          background-color: #201f2f;
          height: 100%;
          width: 100%;
          border-radius: 50%;
        }
        .avatar-img{
          background-image: url('../../assets/img/top-developer.jpeg');
          background-position: 12% 20%;
          background-size: 120%;
          height: 100%;
          width: 100%;
          border-radius: 50%;

        }
      }
    }
}
   
